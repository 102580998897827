@import "../../assets/css/library";

#home {
    display: flex;
    height:100vh;

    #welcome {
        margin-right: $spacing;
    }

}