// colors
$base-clr: #ff9900;
$base-l-clr: lighten($base-clr, 28%);
$dark-clr: #48464E;
$darker-clr: darken($dark-clr, 20%);
$gray-clr: #a1a1a6;
$thrd-clr: #a68444;
$txt-clr: black;
$bg-clr: white;
$fade-clr: lighten($gray-clr, 28%);
$scd-clr: #cce6f1;

$alertClr: #ff1900;
$warnClr : #ff5900;
$validClr : #a6ff00;

$alertClrLighten : lighten($alertClr, 45%);
$warnClrLighten : lighten($warnClr, 45%);
$validClrLighten : lighten($validClr, 45%);

$low-rad: 0.3rem;
$med-rad: 0.8rem;

$border-width: 1px;
$border-style: solid;
$border-color: $scd-clr;
$border: $border-width $border-style $border-color;
$spacing : 0.9rem;

$input-height: 1.5rem;
$input-min-wid: 2.4rem;
$input-font-size: 9pt;
$input-pad: $spacing/3;

$btn-min-wid: 1.7rem;