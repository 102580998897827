.rule-marker {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  margin-right: 0.75rem;
}

.rule-li {
  display: flex;
  flex-direction: row;
  align-items: center;
  list-style: none;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.rule-description {
  color: #696d8c;
}

.none {
  background-color: #f2f2f2;
}

.danger {
  background-color: #f79682;
}

.success {
  background-color: #17d499;
}
