@import "library";

* {
  padding: 0;
  margin: 0;
  font-family: arial, sans-serif;
  box-sizing: border-box;
}

/*****************************************/

::-webkit-scrollbar {
  $size: 0.6rem;
  width: $size; /* width of the entire scrollbar */
  height: $size; /* height of the entire scrollbar */
  margin: 0.1rem;
}
::-webkit-scrollbar-track {
  background: $fade-clr; /* color of the tracking area */
  border-radius: 100rem; /* roundness of the tracking area */
}
::-webkit-scrollbar-thumb {
  background-color: $base-l-clr; /* color of the scroll thumb */
  border-radius: 100rem; /* roundness of the scroll thumb */
  border: 0.15rem solid $fade-clr; /* creates padding around scroll thumb */
  &:hover {
    background: $base-clr;
    border-width: 0.1rem;
  }
}

/*****************************************/

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 90%;

  display: flex;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100%;
  background-color: $fade-clr;
}

#root {
  display: flex;
  flex: 1;
  width: 100vw;
  height: 100vh;
}
main {
  overflow-y: auto;
  height: 100%;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

label {
  &::selection,
  &::-moz-selection {
    background: transparent;
    color: default;
  }
  user-select: none;
  -moz-user-select: none;
}

ul {
  li {
    margin-left: $spacing * 1.5;
  }
}

label {
  &::selection,
  &::-moz-selection {
    background: transparent;
    color: default;
  }
  user-select: none;
  -moz-user-select: none;
}

table {
  border-radius: $low-rad;
  border-collapse: collapse;
  overflow: hidden;

  tr {
    height: 1.5rem;
  }

  > tbody {
    tr {
      &:nth-child(odd) {
        background-color: white;
      }
    }
  }
}

// hr
hr {
  width: 75%;
  height: 1px;
  text-align: start;
  border-width: 1px;
  background: #eaeaeb;
  border: none;
  margin-bottom: 1.5em;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

svg {
  --color: black;
  > .fill {
    fill: var(--color);
  }
  > .a {
    --color: #{$base-clr};
  }
  > .b {
    --color: black;
  }
  > .c {
    --color: white;
  }
  > .d {
    --color: #{$dark-clr};
  }
}

// Main title of pages
.titlePage {
  font-size: 24px;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0.3em;
}

// hr
hr {
  width: 75%;
  height: 1px;
  text-align: start;
  border-width: 1px;
  background: #eaeaeb;
  border: none;
  margin-bottom: 1.5em;
}

// display the empty space on Simulation and Budget
.emptyState {
  color: #48474f;
  align-items: center;
  padding: 2em;
}

.emptyTitle {
  margin-bottom: 2em;
  font-weight: bold;
}

.emptySvg {
  height: 228px;
  width: 228px;
}

.emptyProposition {
  margin-top: 2.5em;
}

.breaker {
  display: block;
  height: 0.1rem;
  width: 60%;
  background-color: black;
  margin: 1rem auto;
  border-radius: 100vw;
}
.array {
  flex-wrap: nowrap !important;
  overflow: auto;

  > * {
    flex-wrap: nowrap !important;
  }
  .input {
    margin: initial !important;
  }
}
.marg-t {
  margin-top: $spacing !important;
}
.marg-b {
  margin-bottom: $spacing !important;
}
.marg-s {
  margin-left: $spacing !important;
  margin-right: $spacing !important;
}
.marg-a {
  margin: auto;
}
.padd-r {
  padding-right: $spacing !important;
}
.padd-s {
  padding-left: $spacing !important;
  padding-right: $spacing !important;
}
.padd-between > *:not(:last-child) {
  padding-right: $spacing !important;
}

.marg-between-b > *:not(:last-child) {
  margin-bottom: $spacing !important;
}
.marg-between-b-otherInc > *:nth-child(even) {
  margin: auto 8px;
}
.marg-s-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}
.padd-b {
  padding-bottom: $spacing;
}
.align-b {
  display: flex;
  > * {
    margin-top: auto !important;
  }
}
.overflow {
  overflow: auto;
}
.min-cnt {
  max-width: min-content;
}
.maxw-25r {
  max-width: 25rem;
}
.minw-25r {
  min-width: 25rem;
}
.minw-prct-60 {
  min-width: 60%;
}
.whole-height {
  height: 100vh;
}
.flex-1 {
  flex: 1;
}
.flex-2 {
  flex: 2;
}
.flex-3 {
  flex: 3;
}
.flex-4 {
  flex: 4;
}
.flex-5 {
  flex: 5;
}
.flex-6 {
  flex: 6;
}
.flex-7 {
  flex: 7;
}
.fill {
  flex: 1;
  width: 100%;
}
.middle {
  text-align: center;
  align-self: center;
  place-items: center;
}
.centered {
  text-align: center;
  margin: 0 auto;
  place-content: space-evenly !important;
}
.space-between {
  place-content: space-between !important;
}
.space-around {
  place-content: space-around !important;
}
.column {
  display: flex;
  flex-direction: column;
}

.col-is-9 {
  width: 75%;
  align-items: flex-end;
}

.col-is-3 {
  width: 25%;
  align-items: flex-end;
  align-self: flex-end;
}

.row {
  display: flex;
  flex-direction: row !important;

  &-reverse {
    display: flex;
    flex-direction: row-reverse;
  }
}
.labeled-block {
  display: flex;
  flex-direction: column;

  > h3 {
    margin-bottom: $spacing / 2;
  }
  > div:last-of-type {
    border: $border;
    border-radius: $med-rad;
    padding: $spacing;
    min-width: fit-content;
  }
}
.block {
  display: flex;
  padding: $spacing;
}
.word-breaker {
  overflow-wrap: break-word;
  word-wrap: break-word;
  hyphens: auto;
}
.clip-text {
  text-overflow: clip;
  white-space: nowrap;
  overflow: hidden;
}
.wrapper {
  flex-wrap: wrap;
}
.whole-width {
  width: 100%;
}
.centered-block {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: auto;
  // padding: $spacing;
  border-radius: $low-rad;
  vertical-align: middle;
}
.dropdown {
  > div:first-of-type {
    &::after {
      content: "";
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 10px 5px 0 5px;
      border-color: black transparent transparent transparent;
      position: absolute;
      right: 10px;
      top: 5px;
    }
    &.showed::after {
      border-width: 0px 5px 10px 5px;
      border-color: transparent transparent black transparent;
    }
  }
}
.mh-30vh {
  max-height: 30vh;
}
.wr-4 {
  max-width: 4rem;
  min-width: 4rem;
}
.w-70 {
  width: 70%;
}
.w-80 {
  width: 80%;
}
.w-100 {
  width: 100%;
}
.cursed {
  cursor: pointer;
}
.icon {
  display: flex;
  $size: 4rem;
  width: $size;
  margin-top: 16px;
  margin-bottom: 16px;

  > svg {
    fill: white;
    width: 40%;
    margin: auto;
    > .fill {
      fill: white;
    }
  }

  > img {
    width: 70%;
    margin-top: 16px;
    margin: auto;
  }
}
.wall {
  position: absolute !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 999;
  display: flex;

  .popup {
    margin: auto;
    background-color: $bg-clr;
    display: flex;
    flex-direction: column;
    padding: $spacing;
  }
}

// Button Create on Simulation and Budget
.create {
  display: flex;
  width: 15%;
  justify-content: flex-start;
}

// Animation for New simulation button --- --- ---
@keyframes pulse {
  0% {
    transform: scale(0);
    opacity: 0;
  }
  33% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(3);
    opacity: 0;
  }
}

.buttonCreate {
  transition: background 0.3s, transform 0.3s, box-shadow 0.3s;
  will-change: transform;
  &:active {
    transform: translate3d(0, 1px, 0);
  }
}

.pulse {
  &:before,
  &:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 50%;
    width: 20px;
    height: 20px;
    opacity: 0;
    margin: auto;
  }
  &:before {
    animation: pulse 1.5s infinite linear;
  }
  &:hover:before,
  &:hover:after {
    display: none;
  }
}
// --- END Animation for New simulation button

// Actions on Simulation or Budget records
.actionBar {
  justify-content: flex-start;
  width: 85%;
}

.actionsOnRecord {
  display: flex;
  width: 70%;
  margin: 0px 8px !important;
  align-items: center;
}
// --- END Actions on Simulation or Budget records

.bubble-ctn {
  $size: 1.2rem;
  position: relative;
  margin: auto 0.5rem auto 0;

  .bubble {
    cursor: default;
    display: block;
    margin-left: 8px;
    border-radius: 100%;
    border-style: solid;
    border-width: 1px;
    border-color: #fea500;
    background-color: white;
    color: #fea500;
    width: $size;
    height: $size;
    text-align: center;
    &:hover ~ .msg {
      position: absolute;
      display: flex;
    }
    &-error {
      background-color: $alertClr;
    }
    ~ .msg {
      display: none;
      pointer-events: none;
    }
  }
}
.msg {
  position: absolute;
  top: 0;
  left: 0;
  //transform: translate(-50%, -50%);
  z-index: 2;
  flex-direction: column;
  background-color: white;
  box-shadow: 0px 5px 15px 0px rgba(0,0,0,0.3);
  padding: $spacing/2;
  border: $border;
  border-color: $scd-clr;
  border-radius: $med-rad;
  max-width: 19rem;
  width: max-content;

  &-error {
    border-color: $alertClr;
    background-color: $alertClrLighten;
    .arrow {
      background-color: $alertClrLighten;
    }
  }

  > div > h4 {
    font-weight: bold;
  }

  > p,
  > ul {
    display: flex;
    flex-wrap: wrap;
    padding: 0 $spacing;
    border-radius: $low-rad;
  }
  > ul {
    > li {
      flex-direction: row;
      padding: $spacing/2;
      margin: 0;
      width: max-content;
    }
  }

  &:hover {
    position: absolute;
    display: flex;
  }
  .arrow {
    position: absolute;
    top: 10px;
    right: -5px;
    width: 10px;
    height: 10px;
    transform: rotate(45deg);
    background-color: $fade-clr;
  }
}
.notif {
  display: flex;
  flex-direction: column;
  border: $border;
  border-radius: $med-rad;
  max-width: 25rem;
  margin: auto;
  overflow: hidden;

  > ul {
    padding: 0 $spacing;
    border-radius: $low-rad;
    display: flex;
    flex-wrap: wrap;
    padding: $spacing/2;
    list-style: none;

    &[type="0"] {
      background-color: $alertClrLighten;
    }
    &[type="1"] {
      background-color: $warnClrLighten;
    }
    &[type="2"] {
      background-color: $validClrLighten;
    }

    > li {
      flex-direction: row;
      padding: $spacing/2;
      margin: 0;
      width: max-content;
    }
  }
}

@keyframes loading-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loading {
  position: relative;
  * {
    visibility: hidden;
  }
  &::after {
    position: absolute;
    content: "";
    $size: 2rem;
    top: calc(50% - #{$size/2});
    left: calc(50% - #{$size/2});
    width: $size;
    height: $size;
    background: no-repeat url(../graphics/spiral.svg);
    animation: loading-spin infinite 1s linear;
  }
}

// Main title of pages
.titlePage {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0.3em;
}

.white-container {
  display: flex;
  flex-direction: column;
  height: min-content;
  padding: $spacing * 2;
  margin: 0 auto;
  background-color: white;
  border: 2px solid #e6ecf0;
  border-radius: $med-rad;
  align-items: center;
  min-width: 26rem;
}

.form-container {
  display: flex;
  flex-direction: column;
  height: min-content;
  padding: $spacing * 2;
  margin: 0 auto;
  background-color: white;
  border: 2px solid #e6ecf0;
  border-radius: $med-rad;
  align-items: center;
  // max-width: 80vw;
  min-width: 26rem;

  > .label {
    margin-bottom: $spacing;
  }

  form {
    display: flex;
    flex-direction: column;
    flex: 1;
    width: 100%;

    > .column {
      > :not(:last-child) {
        margin-bottom: $spacing;
      }

      .input {
        margin-left: $spacing/2;
        margin-right: $spacing/2;
        margin-top: auto;
        margin-bottom: $spacing/2;
      }

      .column,
      .row {
        flex-wrap: wrap;
        place-content: space-between;
      }
    }
  }
}

.link {
  text-decoration: none;
  color: $base-clr;
  &:hover {
    text-decoration: underline;
  }
}

.input {
  position: relative;

  &.small {
    input {
      max-width: 5rem !important;
      min-width: 4rem;
      min-height: $input-height;
    }
  }

  input {
    font-size: $input-font-size;
  }

  .input-ctn {
    display: flex;
  }

  .input-label {
    font-size: $input-font-size;
    font-weight: bold;
    text-align: left;
    cursor: pointer;
    margin-bottom: 0.4rem;
  }

  &-range {
    min-height: $input-height;
    $size: 1rem;

    input {
      -webkit-appearance: none; /* Hides the slider so that custom slider can be made */
      appearance: none;
      width: 100%; /* Specific width is required for Firefox. */
      background: $fade-clr; /* Otherwise white in Chrome */
      border-radius: 100rem;
      height: 0.5rem;
      margin: 0.5rem 0;
      border: $border;
      cursor: pointer;
      opacity: 0.7;
      -webkit-transition: 0.2s;
      transition: opacity 0.2s;

      &:hover {
        opacity: 1;
      }
      &:focus {
        outline: none;
        opacity: 1;
      }
      // &::-ms-track,
      // &::-moz-range-track,
      &::-webkit-slider-runnable-track {
        background: transparent;
      }
      &:focus::-ms-fill-lower {
        background-color: $base-clr;
      }
      &:focus::-ms-fill-upper {
        background: transparent;
      }
      // ::-ms-thumb,
      // ::-moz-range-thumb,
      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        color: $base-clr;
        background: $base-clr;
        border: none;
        border-radius: 100rem;
        box-sizing: border-box;
        width: $size;
        height: $size;
        cursor: pointer;
      }
    }
  }

  &-select {
    &[error="true"] {
      > .input-label {
        color: $alertClr;
      }
      select {
        color: $alertClr;
        border-color: $alertClr;
        background-color: $alertClrLighten;
      }
    }
    select {
      border: $border;
      border-radius: $med-rad;
      text-align-last: center;
      height: fit-content;
      min-height: $input-height;
      padding: $spacing/2;
      font-size: $input-font-size;
      &:focus {
        outline: none;
        border-color: $base-clr;
      }

      &:-internal-list-box {
        text-align: center;
      }

      > option {
        text-align: center;
        cursor: pointer;
        margin-bottom: 0.4rem;
      }
    }
  }

  &-password {
    > div {
      display: flex;

      > svg {
        $size: 2rem;
        height: $size;
        width: $size;
        fill: $txt-clr;
        margin: auto 0 auto 1rem;
        cursor: pointer;
      }
    }
  }

  &-number {
    & [percentage] {
      &::after {
        content: "%";
        margin-left: $spacing/2;
        color: black;
      }
    }
    input {
      text-align: right;
    }
  }

  &-checkbox,
  &-select,
  &-date,
  &-number,
  &-text,
  &-password {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    min-height: $input-height;
    input {
      flex: 1;
      vertical-align: middle;
      border-radius: $med-rad;
      border: $border;
      outline: none;
      padding: $input-pad;
      height: fit-content;
      min-height: $input-height;
      width: 100%;
      min-width: $input-min-wid;

      &:focus {
        border-color: $base-clr;
      }
    }

    &[error="true"] {
      > .input-label {
        color: $alertClr;
      }
      input {
        background-color: $alertClrLighten;
        border-color: $alertClr;
      }
    }
  }

  &-checkbox {
    flex-direction: row;

    .checker {
      cursor: pointer;
      display: flex;
      place-content: center;
      $size: 1rem;
      border: $border;
      border-radius: $low-rad;
      background-color: white;
      margin: 0 auto;
      margin-left: 0.2rem;
      min-width: $size;
      max-width: $size;
      min-height: $size;
      max-height: $size;

      &:focus {
        border-color: $base-clr;
      }
      &:hover {
        background-color: $base-l-clr;
        &::after {
          display: block;
        }
      }

      &::after {
        content: "";
        margin-top: 1px;
        width: 3px;
        height: 8px;
        transform: rotate(40deg);
        border: solid white;
        border-width: 0 2px 2px 0;
        display: none;
      }
    }

    input[type="checkbox"] {
      visibility: hidden;
      position: absolute;
      z-index: -1;
      width: 0;

      &[disabled] ~ .checker {
        cursor: default;
        background-color: $fade-clr;
        &:hover::after {
          display: none;
        }
      }

      &:checked {
        ~ .checker::after {
          display: block !important;
        }
        &:not([disabled]) ~ .checker {
          background-color: $base-clr;
          border-color: transparent;
        }
        &[disabled] ~ .checker::after {
          border-color: grey;
        }
      }
    }

    &[error="true"] {
      color: $alertClr;
      border-color: $alertClr;

      > .input-label {
        color: $alertClr;
      }

      .checker {
        background-color: initial !important;
        border-color: $alertClr !important;
        &::after {
          border-color: $alertClr;
        }
      }
    }
  }

  &-submit {
    text-align: center;
    align-self: center;

    input {
      font-size: $input-font-size;
      text-transform: uppercase;
      color: white;
      border: none;
      border-radius: $low-rad;
      background-color: $base-clr;
      padding: $input-pad;
      max-width: fit-content;
      cursor: pointer;
      height: fit-content;

      &[disabled] {
        background-color: $gray-clr;
        color: $darker-clr;
      }

      &:focus:not([disabled]),
      &:hover:not([disabled]) {
        outline: none;
        background-image: linear-gradient(
          to top,
          $base-clr,
          lighten($base-clr, 10%)
        );
      }
    }
  }
}

.button {
  font-size: $input-font-size;
  color: white;
  border: none;
  border-radius: $med-rad;
  margin: 8px;
  padding: $spacing/2;
  cursor: pointer;
  height: fit-content;
  min-height: fit-content;
  min-width: $btn-min-wid;
  text-align: center;

  &-plain {
    background-color: $base-clr;
    &[disabled] {
      background-color: $gray-clr;
      color: $darker-clr;
    }
  }
  &-hollow {
    background-color: transparent;
    color: $base-clr;
    border: 0.1rem $base-clr solid;
    &:hover:not([disabled]) {
      color: white;
    }
    &[disabled] {
      border-color: $dark-clr;
      color: $dark-clr;
    }
  }
  &:focus {
    outline: none;
  }
  &:hover:not([disabled]) {
    outline: none;
    background-image: linear-gradient(
      to top,
      $base-clr,
      lighten($base-clr, 10%)
    );
  }
}

// CSS resetPassword
.resetPassword p {
  margin-bottom: 16px;
}

// CSS Verification input
.container {
  max-width: 216px;
  margin-bottom: 16px;
}

.characters {
  height: 50px;
}

.character {
  line-height: 50px;
  font-size: 24px;
  background-color: #eee5dc;
  border: 1px solid transparent;
  border-radius: 8px;
  color: #49484f;
  margin-left: 8px;
}

.character--inactive {
  background-color: rgba(255, 255, 255, 0.1);
  box-shadow: none;
}

.character--selected {
  border: 1px solid #49484f;
}

.verification-input__character {
  height: 100%;
  flex-grow: 1;
  flex-basis: 0;
  width: 50px;
  text-align: center;
}

// CSS React-TOASTify
.Toastify__toast-body {
  color: #49484f;
}

.Toastify__toast-container {
  display: flex;
  width: 500px;
}

