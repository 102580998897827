@import "../../assets/css/library";

#menu {
	background-color: #48474f; 
	height: 100vh;
	width: 4rem;

	#userPic figure {
		border-radius: 100%;
		overflow: hidden;
	}

	.menu-item {
		display: flex;
		list-style: none;
		$size: 4rem;
		height: $size;
		margin: $spacing/2 0;
		&:first-child{
			margin-top: 0;
		}	

		> a {
			display: flex;
			margin: auto;
			margin-top: 16px;;
			width: 100%;
			color: white;
			text-decoration: none;
			text-align: center;

			> * {
				margin: auto;
			}

			> figure {
				display: flex;
				width: $size;
			}
		}
	}

	> nav {
		> ul {
			margin: 0 auto;
			margin-top: 48px;
		}
	}
}
