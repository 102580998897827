@import "../../assets/css/library";

#login {
	header {
		height: 8rem;
		display: flex;
		margin-top: 3rem;
		margin-bottom: 3rem;

		#logo {
			$size: 100%;
			height: $size;
			width: $size;
		}
	}

	.login-form {
		display: flex;
		flex-direction: column;
		background-color: $bg-clr;

		> h2 {
			margin-bottom: $spacing;
		}
	}

	.form-container {
		width: 33rem;
	}
}
