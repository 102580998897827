@import "../../assets/css/library";

.card > div {
	position: relative;
	height: fit-content;
	padding: $spacing;
	margin: $spacing;
	border-radius: $low-rad;
	background-color: $bg-clr;
	overflow: hidden;
	width: 15rem;
    min-height: 15rem;
    
    > svg {
        $size: 3rem;
        height: $size;
        width: $size;
    }

	> div {
        margin: auto;
		> h2 {
			font-weight: bold;
			margin: $spacing;
		}

		> p {
			text-align: center;
		}
    }
    
    .button {
        margin: $spacing auto;
    }

	&::before {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		width: 100%;
		height: $low-rad;
		background-color: $base-clr;
	}
}
